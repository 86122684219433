
//Plugin
// Slick slider
//=require ../libs/slick-carousel/slick/slick.min.js
//=require ../libs/fancybox/dist/jquery.fancybox.min.js
//Plugin mmenu
//=require ../libs/jquery.mmenu.all.min.js


//Plugin oldLibs
//=require ./components/othersLibs.js


// // Custom JS
// $(document).ready(function () {
// 	console.log('jQuery:' + jQuery.fn.jquery);
// });


//oldScripts
//=require ./components/oldScripts.js


// new Vue({
//   el: '#carousel3d',
//   data: {
//     slides: 3
//   },
//   components: {
//     'carousel-3d': window['carousel-3d'].Carousel3d,
//     'slide': window['carousel-3d'].Slide
//   }
// });

$("#navToggle").click(function(){
	$("#my-menu").toggleClass("active");
});

$(function(){

	$("#navToggle").click(function(){
		$(this).find(".main-nav__toggle").toggleClass("active"),
		$(".overlay").toggleClass("open"),
		$("body").toggleClass("locked");
	});

	// if ($(window).width() > 992){

	// }
	$(document).ready(function() {
		$(window).on("scroll", function() {
			var fromTop = $(document).scrollTop();
			$(".page-header").toggleClass("fixed", (fromTop > 0));
			$("main").toggleClass("headerFixed", (fromTop > 0));
		});
	});
});

$(function(){
	if ($(window).width() < 768){
		$('#my-menu').mmenu({
			extensions: ["border-full"],
			navbar: {
								title: ''
							},
		});
	 }
});

$(function() {

	$('.priceSelect__title--js').click(function() {
		$(this).parent().toggleClass('priceSelect--active');
		$(this).toggleClass('priceSelect__title--active');
		// $(this).next().slideToggle();
		$(this).next().slideToggle(100);
	});


	$('.blogPage .blogHeading').each(function(id){

		var elem_id = 'sec' + id;

		$(this).attr( 'id', elem_id );

		$('.priceSelect__list--js').append('<li class="priceSelect__listItem"><a href="#'+ elem_id +'" class="priceSelect__listItemLink">' + $(this).text() + '</a></li>');

	});

	$("a.priceSelect__listItemLink").click(function() {
		var elementClick = $(this).attr("href")
		var destination = $(elementClick).offset().top - 25;

		$("a.priceSelect__listItemLink").removeClass('priceSelect__listItemLink--active');
		$(this).addClass('priceSelect__listItemLink--active');

		jQuery("html:not(:animated),body:not(:animated)").animate({
			scrollTop: destination
		}, 800);
		return false;
	});

	if ( $(".blogHeading").length < 1 ){
		$(".priceSelect__wrap").hide();
	}


});

$(function() {

	$(".questions__item").click(function() {
		
		if ( $(this).hasClass("active") ) {
			$(this).removeClass("active");
			$(this).find('.questions__text').removeClass('active');
			$(this).siblings(".questions__answerWrap").slideUp(300);
		}else{
			$(".questions__item").removeClass("active");
			$(".questions__item").find('.questions__text').removeClass('active');
			$(".questions__answerWrap").slideUp(300);
			$(this).addClass("active");
			$(this).find('.questions__text').addClass('active');
			$(this).siblings(".questions__answerWrap").slideDown(300);
		}

	});

});


$(function(){
	if ( $('.reviews').length > 0 )
	{
		if ($(window).width() < 670)
		{
			$('.reviews__listItem--empti').remove();
		}
	}
});


$(function(){
	if ( $('.js-reviews__list--slider').length > 0 )
	{
		$('.js-reviews__list--slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 670,
					settings: "unslick"
				}
			]
		});
	}
});

