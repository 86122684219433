$(function() {
		$(".employees-js").slick({
				slidesToShow: 4,
				slidesToScroll: 1,
				draggable: !0,
				dots: !1,
				arrows: !0,
				prevArrow: '<div class="slick__arrow slick__arrow--left">',
				nextArrow: '<div class="slick__arrow slick__arrow--right">',
				mobileFirst: !1,
				centerMode: !1,
				autoplay: !0,
				autoplaySpeed: "4000",
				variableWidth: !1,
				adaptiveHeight: !1,
				responsive: [{
						breakpoint: 1200,
						settings: {
								slidesToShow: 3
						}
				}, {
						breakpoint: 992,
						settings: {
								slidesToShow: 2
						}
				}, {
						breakpoint: 768,
						settings: {
								slidesToShow: 1,
								dots: !0,
								arrows: !1,
								variableWidth: !0,
								dotsClass: "slick-dots slick-dots--portfolio"
						}
				}, {
						breakpoint: 576,
						settings: {
								slidesToShow: 1,
								arrows: !1,
								dots: !0,
								dotsClass: "slick-dots slick-dots--portfolio",
								variableWidth: !0
						}
				}]
		})
}), $(function() {
		$(".portfolio__slick-js").slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				draggable: !1,
				dots: !0,
				dotsClass: "slick-dots slick-dots--portfolio",
				arrows: !0,
				prevArrow: '<div class="slick__arrow slick__arrow--left slick__arrow--left-portfolio">',
				nextArrow: '<div class="slick__arrow slick__arrow--right slick__arrow--right-portfolio">',
				mobileFirst: !1,
				infinite: !0,
				speed: 500,
				fade: !0,
				cssEase: "linear",
				centerMode: !1,
				variableWidth: !1,
				adaptiveHeight: !1,
				infinite: !1,
				responsive: [{
						breakpoint: 1200,
						settings: {
								slidesToShow: 1
						}
				}, {
						breakpoint: 768,
						settings: {
								arrows: !1
						}
				}]
		})
}), $(function() {
		$(".portfolio__servicesList-js").slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				draggable: !1,
				dots: !1,
				arrows: !0,
				prevArrow: '<div class="portfolio__arrow portfolio__arrow--right">',
				nextArrow: '<div class="portfolio__arrow portfolio__arrow--left">',
				mobileFirst: !0,
				infinite: !0,
				centerMode: !1,
				variableWidth: !0,
				adaptiveHeight: !1,
				infinite: !1,
				responsive: [{
						breakpoint: 576,
						settings: {
								slidesToShow: 2
						}
				}, {
						breakpoint: 768,
						settings: "unslick"
				}]
		})
}), $(function() {
		$(".apartment__list-js").slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				draggable: !1,
				dots: !1,
				arrows: !1,
				mobileFirst: !0,
				infinite: !0,
				centerMode: !1,
				variableWidth: !0,
				adaptiveHeight: !1,
				responsive: [{
						breakpoint: 576,
						settings: {
								slidesToShow: 2
						}
				}, {
						breakpoint: 768,
						settings: "unslick"
				}]
		})
}), $(function() {
		$(".accordion__list-js").slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				draggable: !1,
				dots: !1,
				arrows: !1,
				mobileFirst: !0,
				infinite: !0,
				centerMode: !1,
				variableWidth: !0,
				adaptiveHeight: !1,
				infinite: !1,
				responsive: [{
						breakpoint: 769,
						settings: "unslick"
				}]
		})
}), $(function() {
		$(".cleaning-type__list-js").slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				draggable: !1,
				dots: !1,
				arrows: !1,
				mobileFirst: !0,
				infinite: !0,
				centerMode: !1,
				variableWidth: !0,
				adaptiveHeight: !1,
				responsive: [{
						breakpoint: 576,
						settings: {
								slidesToShow: 2
						}
				}, {
						breakpoint: 992,
						settings: "unslick"
				}]
		})
}), $(function() {
		$(".portfolio__before-after-js").twentytwenty()
}), $(function() {
		document.querySelector(".categories-js").querySelector(".categories__buttonWrap").onclick = function() {
				$(this).toggleClass("active"), $(".categories__description--hidden").slideToggle("medium").css("display", "block")
		}
}), $(document).ready(function() {
		$(".tab_content-portfolio").hide(), $("ul.tabs-portfolio li:first").addClass("active").show(), $(".tab_content-portfolio:first").show(), $("ul.tabs-portfolio li").click(function() {
				$("ul.tabs-portfolio li").removeClass("active"), $(this).addClass("active"), $(".tab_content-portfolio").hide();
				var e = $(this).find("a").attr("href");
				return $(e).fadeIn(), !1
		})
}), $(document).ready(function() {
		$(".tab_content-gallery").hide(), $("ul.tabs-gallery li:first").addClass("active").show(), $(".tab_content-gallery:first").show(), $("ul.tabs-gallery li").click(function() {
				$("ul.tabs-gallery li").removeClass("active"), $(this).addClass("active"), $(".tab_content-gallery").hide();
				var e = $(this).find("a").attr("href");
				return $(e).fadeIn(), !1
		})
}), $(document).ready(function() {
		$(".tab_content-accordion-1").hide(), $("ul.tabs-accordion-1 li:first").addClass("active").show(), $(".tab_content-accordion-1:first").show(), $("ul.tabs-accordion-1 li").click(function() {
				$("ul.tabs-accordion-1 li").removeClass("active"), $(this).addClass("active"), $(".tab_content-accordion-1").hide();
				var e = $(this).find("a").attr("href");
				return $(e).fadeIn(), !1
		})
}), $(document).ready(function() {
		$(".tab_content-2").hide(), $("ul.tabs-2 li:first").addClass("active").show(), $(".tab_content-2:first").show(), $("ul.tabs-2 li").click(function() {
				$("ul.tabs-2 li").removeClass("active"), $(this).addClass("active"), $(".tab_content-2").hide();
				var e = $(this).find("a").attr("href");
				return $(e).fadeIn(), !1
		})
}), $(document).ready(function() {
		$(".tab_content-cleaning-type").hide(), $("ul.tabs-cleaning-type li:first").addClass("active").show(), $(".tab_content-cleaning-type:first").show(), $("ul.tabs-cleaning-type li").click(function() {
				$("ul.tabs-cleaning-type li").removeClass("active"), $(this).addClass("active"), $(".tab_content-cleaning-type").hide();
				var e = $(this).find("a").attr("href");
				return $(e).fadeIn(), !1
		})
}), $(document).ready(function() {
		$(".tab_content").hide(), $("ul.tabs-about li:first").addClass("active").show(), $(".tab_content-about:first").show(), $("ul.tabs-about li").click(function() {
				$("ul.tabs-about li").removeClass("active"), $(this).addClass("active"), $(".tab_content-about").hide();
				var e = $(this).find("a").attr("href");
				return $(e).fadeIn(), !1
		})
}), $(document).ready(function() {
		$(".accordion__item > p").on("click", function() {
				$(this).hasClass("active") ? ($(this).removeClass("active"), $(this).siblings(".content").slideUp(300)) : ($(".accordion__item > p").removeClass("active"), $(this).addClass("active"), $(".content").slideUp(300), $(this).siblings(".content").slideDown(300))
		})
}), $(function() {
		$(window).width() < 992 && $(".js-menu").slinky({
				title: !0,
				resize: !1
		})
}), $(function() {
		991 < $(window).width() && (document.querySelector(".main-nav__item--services").querySelector(".main-nav__link--services").onclick = function(e) {
				e.preventDefault, $(this).toggleClass("active"), $(this).next().slideToggle("medium").css("display", "block")
		})
}), $(function() {
		991 < $(window).width() && (document.querySelector(".main-nav__item--about").querySelector(".main-nav__link--about").onclick = function() {
				$(this).toggleClass("active"), $(this).next().slideToggle("medium").css("display", "block")
		})
}), $("area").on("click", function(e) {
		e.preventDefault(), $(".accordion__text-block").toggleClass("none")
}), $(function() {
		var t = $(".modal-price__steps").children(".formStep");
		$(t).hide(), $(t[0]).show();
		var i = 0;
		$(".nextBtnJs").click(function() {
				var e;
				i == t.length - 2 && ($(this).hide(), $(".submitBtnJs").show(), $(".privacyJs").show()), e = ++i, $(".stepCounterJs").html(e + 1), $(t).hide(), $(t[e]).show()
		})
}), $(function() {
		diafan_ajax.success.feedback_add = function(e, t) {
				t.result && ($.fancybox.close(), setTimeout(function() {
						$.fancybox.open({
								src: "#modal-feedback-ok",
								type: "inline"
						})
				}, 200), setTimeout(function() {
						$.fancybox.close()
				}, 3500))
		}
}), $(function() {
		$(window).width() < 991 && $(document).ready(function() {
				$(".questions__item > .questions__text").on("click", function() {
						$(this).hasClass("active") ? ($(this).removeClass("active"), $(this).siblings(".questions__answerWrap").slideUp(300)) : ($(".questions__item > .questions__text").removeClass("active"), $(this).addClass("active"), $(".questions__answerWrap").slideUp(300), $(this).siblings(".questions__answerWrap").slideDown(300))
				})
		})
}), $(function() {
		991 < $(window).width() && $(".subMenuJs").click(function() {
				$(this).toggleClass("active"), $(this).next().slideToggle("medium").css("display", "block")
		})
}), $(function() {
		$(".twentytwenty-left-arrow").html('<span class="twentytwenty-left-arrow__text">До</div>'), $(".twentytwenty-right-arrow").html('<span class="twentytwenty-right-arrow__text">После</div>')
}), $(function() {
		null == $(".categories__description--hidden").html() && $(".categories__buttonWrap").hide()
});